@import url("https://use.typekit.net/qlc2ljq.css");

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#table {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(4){background-color: #000C66; color: white}

#table tr:hover {background-color: #ddd;}
#table tr:nth-child(4):hover{background-color: #000C66; color: white}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #000C66;
  color: white;
}

/*.billed-selector-top {
  display: none;
  padding-top: 35px;
  font-size: 15px;
  text-align: center;
}

.mobile-view {
  display: none !important;
}*/

@media (max-width: 1024px) {
  /*.billed-selector-table,
  .start-or-purchase,
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block !important;
  }*/

  .caption-wrapper {
    max-width: calc(100vw - 20px);
    margin-left: 30px;
  }

  .table-wrapper {
    overflow-x: auto;
    max-width: calc(100vw - 20px);
    margin-left: 30px;
  }
}

.my-swal {
  z-index: 300000 !important;
}

li {
  display: flex;
  flex-direction: row;
  align-items: center;
}