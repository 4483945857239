.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  margin: auto; /* Center horizontally */
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}