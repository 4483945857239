/* .tableHead {
  width: 100%;
  background: violet;
  cursor: pointer;
  display: flex;
  user-select: none;
} */

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  border: none;
}

.stickyAvg {
  position: sticky;
  z-index: 20;
  border-collapse: collapse;
}

.stickyStd {
  position: sticky;
  z-index: 20;
  border-collapse: collapse;
}

thead th {
  background-color: #f9fafb;
  color: #475467;
  padding: 8px;
  user-select: none;
  text-align: start;
  font-weight: 500;
}

th,
td {
  padding: 8px;
  background: #fff;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}

tr {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-radius: 20px;
  width: 100%;
  border-left: #eaecf0 1px solid;
  border-right: #eaecf0 1px solid;
  /*user-select: none;*/
}

.tableStdAndAvg {
  color: #101828;
  font-weight: 600;
}

.textLeft {
  text-align: end;
}

.tableStd {
  background-color: #eff8ff;
}

.tableAvg {
  background-color: #f2f4f7;
}

.tableWrapper {
  border: 1px solid #eaecf0;
  border-radius: 20px;
  padding: 20px 0;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  padding: 14px 24px 18px;
  border-left: #eaecf0 1px solid;
  border-right: #eaecf0 1px solid;
  border-bottom: #eaecf0 1px solid;
  border-radius: 0 0 12px 12px;
}

.paginationButton {
  background-color: #fff;
  border-radius: 64px;
  padding: 8px 14px 8px 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.activePaginationButton {
  background-color: #f9fafb;
  border-radius: 64px;
  padding: 8px 14px 8px 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.sortHeaderContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.stdAvgContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stdAvgSelected {
  color: #30077c;
  font-size: 14px;
  font-weight: 500;
}

.stdAvgBorderedItem {
  border: 1px solid #400aa5;
  border-radius: 20px;
  padding: 2px 5px;
  width: fit-content;
}

.stdAvgItemConatiner {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}
