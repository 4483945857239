.outerContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: fit-content;
  letter-spacing: 0em;
  font-family: Manrope;
}

.selectValue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.selectLabel {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.options {
  margin-top: 5px;
  width: 100%;
  max-height: 432px;
  overflow-y: auto;
}

.optionsLabel {
  padding: 16px 12px;
  font-weight: 600;
  border-bottom: #d0d5dd 1px solid;
  display: flex;
  justify-content: space-between;
}

.optionsVisible {
  display: block;
}

.option {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.option:hover {
  background-color: #f0f0f0;
}

.inputLabel {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.searchContainer {
  padding: 16px 12px;
  border-bottom: #d0d5dd 1px solid;
}

.firstTag {
  border-radius: 16px;
  background-color: #f9f5ff;
  color: #30077c;
  padding: 5px 15px;
}

.otherTags {
  background-color: #f2f4f7;
  padding: 5px 15px;
  border-radius: 14px;
  color: #344054;
}

.addTagButton {
  padding: 1px 1px;
  border-radius: 14px;
  border: 1px solid #30077c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30077c;
  cursor: pointer;
}

.clearTagButton {
  padding: 1px 1px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b42318;
  cursor: pointer;
}

.createNewTagButton {
  cursor: pointer;
  color: #30077c;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  /* border-top: 1px solid #d0d5dd; */
}

.createNewTagButton:hover {
  background-color: #f9f5ff;
}
