input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.user-info__promo-wrapper {
    width: 20%;
    display: inline-block;
    margin-top: 10px;
}

@media (max-width: 1024px) {
    .user-info__promo-wrapper {
        width: 60%;
    }
}

.my-swal {
    z-index: 300000 !important;
}