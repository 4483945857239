.cta-caption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.offerPaid .cta-caption span {
  color: #317BFA;
  display: inline;
}

.cta-subcaption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.coach-avatar img {
  border-radius: 20px;
  margin-top: 60px;
}

.cta-benefits {
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;
  padding-bottom: 210px;
  list-style: none;
}

.offerPaid .cta-benefits {
  padding-bottom: 80px;
}

.cta-benefits li {
  margin-bottom: 10px;
}

.offerPaid .cta-benefits li {
  align-items: start;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.offerPaid .offer-toggle-group {
  display: block;
}

.offerPaid .offer-toggle {
  color: #FFFFFF;
  border: 1px solid #FFFFFF !important;
  border-radius: 4px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 9px 18px;
}

.offerPaid .offer-toggle.active {
  color: #FFFFFF;
  background-color: #580CE3;
  border: 1px solid transparent !important;
}

.offer-active-step {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-sum s {
  font-weight: 400;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

.offerPaid .address-autocomplete div[class$="-menu"] {
  z-index: 999;
}

.gif-v2 {
  display: none !important;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .cta-invitational-benefits-wrapper {
    padding-bottom: 0;
  }

  .offer-roadmap,
  .offer-block-caption,
  .gif {
    display: none !important;
  }

  .cta-subcaption,
  .cta-total,
  .cta-benefits,
  .gif-v2 {
    display: block !important;
    padding-bottom: 5%;
  }
  .cta-invitational {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .cta-invitational-benefits-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .cta-invitational-benefits {
    color: #FFFFFF;
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 176.69%;
    padding-top: 40px;  
    list-style: none;
  }
  
  .cta-invitational-benefits li {
    margin-bottom: 10px;
  }

  .cta-invitational img {
    max-width: 100%;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}
