.outerContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.selectActive {
  background-color: #e9ddfd;
  color: #30077c;
}

.selectInActive {
  background-color: #f2f4f7;
  color: #344054;
}

.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: fit-content;
  letter-spacing: 0em;
  font-family: Manrope;
}

.selectWrapper:hover {
  background-color: #f7f7f7;
}

.selectValue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.selectLabel {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.options {
  margin-top: 5px;
  width: 100%;
  max-height: 432px;
  overflow-y: auto;
}

.optionsLabel {
  padding: 16px 12px;
  font-weight: 600;
  border-bottom: #d0d5dd 1px solid;
}

.optionsVisible {
  display: block;
}

.option {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.option:hover {
  background-color: #f0f0f0;
}

.inputLabel {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.searchContainer {
  padding: 16px 12px;
  border-bottom: #d0d5dd 1px solid;
}

.loadingIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearButton {
  cursor: pointer;
  color: #b42318;
}

.inactiveClearButton {
  color: gray;
  cursor: default;
}

.clearButtonContainer {
  padding: 12px 40px 20px;
  display: flex;
  flex-direction: row-reverse;
  color: #b42318;
  font-size: 16px;
  text-align: left;
  font-family: Manrope;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}
