.plans .plans-banner {
  background: url("/images/banners/banner.jpeg");
  background-size: cover;
  min-height: 650px;
}

.plans .plans-banner.players {
  background: linear-gradient(45deg, #101828 0%, #475467 100%);
}

.plans .plans-banner-caption {
  padding-top: 96px;
  padding-left: 112px;
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  color: #ffffff;
}

.plans .plans-banner-subcaption {
  padding-top: 24px;
  padding-bottom: 48px;
  padding-left: 112px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.plans .plans-banner-testimonials {
  background: url("/images/banners/D-A1.svg") no-repeat;
  background-position: 40px 90px;
  background-size: 80%;
  height: 650px;
}

.plans .plans-banner.players .plans-banner-testimonials {
  background: url("/images/banners/D-A2.svg") no-repeat;
  background-position: 40px 90px;
  background-size: 80%;
}

.plans .plans-banner-buttons {
  padding-bottom: 56px;
  padding-left: 112px;
}

.plans .plans-selector-wrapper {
  text-align: center;
  padding-top: 32px;
}

.plans .plans-selector-group {
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 40px;
}

.plans .plans-selector-group button {
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  border: none;
  background: none;
  text-transform: none;
  margin: 0 5px;
}

.plans .plans-selector-group button[aria-pressed="true"] {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  color: #344054;
}

.plans .plans-period-wrapper {
  text-align: center;
  margin-bottom: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.plans .plans-switch-wrapper {
  text-align: left;
  color: #344054;
  margin-top: auto;
  display: flex;
}

.plans .plans-period-switch {
  padding: 6px;
  margin: 0 14px;
}

.plans .plans-period-switch > span:first-child {
  color: #ffffff;
}

.plans .plans-period-switch > span:last-child {
  opacity: 0.38;
  background-color: #000;
  border-radius: 20px;
}

.plans .plans-period-wrapper .Mui-checked + span:last-child {
  background: #4009a5;
  opacity: 1;
}

.plans .plans-switch-wrapper .Mui-checked + span:last-child {
  background: #4009a5;
  opacity: 1;
}

.plans .plan-card {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin: 0 16px;
  padding: 32px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  height: 100%;
}

.plans .add-on-card {
  height: auto;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  border: 2px solid #30077c;
  background: #ffffff;
  font-size: 14px;
  margin-bottom: 15px;
}

.plans .add-on-card > span {
  background: transparent;
  border: 1.5px solid #4009a5;
  padding: 4px 12px;
  border-radius: 16px;
  color: #30077c;
}

.plans .plan-card-boot {
  background: #1d2939;
  color: #ffffff;
}

.plans .plan-card-caption {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.plans .plan-card-caption span {
  background: #f9f5ff;
  mix-blend-mode: multiply;
  border-radius: 16px;
  padding: 4px 12px;
  float: right;
}

.plans .plan-card-boot .plan-card-caption span {
  border: 2px solid #ee46bc;
  color: #ffffff;
  mix-blend-mode: initial;
  background: none;
}

.plans .plan-card-price {
  margin-bottom: 16px;
}

.plans .plan-card-price span {
  font-weight: 400;
  font-size: 53px;
  line-height: 72px;
  color: #101828;
}

.plans .plan-card-boot .plan-card-price span {
  color: #ffffff;
}

.plans .plan-card-boot .with-3d {
  display: block;
}

.plans .plan-card-features-caption {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-top: 32px;
  margin-bottom: 4px;
}

.plans .plan-card-boot .plan-card-features-caption {
  color: #ffffff;
}

.plans .plan-card-features {
  margin-top: 24px;
  list-style: none;
}

.plans .plan-card-features li {
  margin-bottom: 16px;
  display: flex;
}

.plans .plan-card-boot .plan-card-features li span {
  color: #6ce9a6;
}

.plans .plan-card-boot .plan-card-features li s {
  color: #667085;
}

.plans .plan-card-feature-mark {
  border: 2px solid #4009a5;
  border-radius: 25px;
  color: #4009a5;
  vertical-align: middle;
  margin-right: 10px;
}

.plans .plan-card-boot .plan-card-feature-mark {
  border: 2px solid #6ce9a6;
  color: #6ce9a6;
}

.plans .plan-button {
  padding: 12px 20px;
  width: 100%;
  font-weight: 600;
  background: #5c0dee;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin: 32px 0;
  color: #ffffff;
  text-transform: none;
}

.plans .plan-button:hover {
  background: #3c1585;
}

.plans .plan-button-outlined {
  padding: 12px 20px;
  width: 100%;
  font-weight: 600;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #344054;
  margin: 32px 0;
  text-transform: none;
}

.plans .plans-banner-buttons .plan-button {
  width: auto;
}

.plans .plan-compare-caption {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #101828;
  margin-top: 128px;
  margin-bottom: 64px;
}

.plans .plan-compare-table {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.plans .plan-compare-features {
  font-weight: 500;
  color: #101828;
  border-top: 2px solid transparent;
}

.plans .plan-compare-features .plan-compare-rows > div {
  height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.plans .plan-compare-row {
  background: #f9fafb;
}

.plans .plan-compare-features .plan-compare-features-section,
.plans .plan-compare .plan-compare-features-section {
  color: #9662f7;
  font-weight: 600;
  padding-top: 24px;
  height: 88px;
}

.plans .plan-compare {
  border-radius: 16px;
  border-top: 2px solid transparent;
  padding-bottom: 16px;
}

.plans .plan-compare:hover {
  border: 2px solid #5c0dee;
}

.plans .plan-compare:hover .plan-compare-row {
  background: #f9f5ff;
}

.plans .plan-compare .plan-compare-rows > div {
  height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.plans .plan-compare .plan-compare-rows .plan-compare-row-double {
  height: 128px;
}

.plans .plan-compare .plan-card-feature-mark {
  margin: 0;
  border: 2px solid #039855;
  color: #039855;
}

.plans .plan-compare-header {
  position: sticky;
  text-align: center;
  min-height: 305px;
  padding-top: 24px;
  top: 0;
  background: #ffffff;
  border-radius: 16px 16px 0 0;
}

.plans .plan-compare-header-caption {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #5c0dee;
  margin-bottom: 8px;
}

.plans .plan-compare-header-name {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #101828;
  margin-bottom: 8px;
}

.plans .plan-compare-header .plan-button,
.plans .plan-compare-header .plan-button-outlined {
  margin: 32px 24px;
  width: 200px;
}

.plans .plans-testimonials {
  margin-top: 80px;
  margin-bottom: 90px;
  height: 600px;
}

.plans .plans-testimonials > div {
  height: 100%;
}

.plans .plans-testimonials-body {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background: #24055d;
  padding: 64px;
  color: #ffffff;
  height: 100%;
  position: relative;
}

.plans .plans-testimonials-stars {
  padding-bottom: 24px;
}

.plans .plans-testimonials-star {
  color: #fec84b;
  margin-right: 4px;
}

.plans .plans-testimonials-text {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 32px;
}

.plans .plans-testimonials-photo {
  background: url("/images/testimonials/sean.jpeg") no-repeat center center;
  background-size: cover;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.plans .plans-testimonials-photo.coach2 {
  background: url("/images/testimonials/erika.jpeg") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.coach3 {
  background: url("/images/testimonials/david.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.coach4 {
  background: url("/images/testimonials/mike.jpeg") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player1 {
  background: url("/images/testimonials/winnie.jpeg") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player2 {
  background: url("/images/testimonials/caden.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player3 {
  background: url("/images/testimonials/glenn.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player4 {
  background: url("/images/testimonials/alexis.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-selectors {
  padding-top: 32px;
  position: absolute;
  bottom: 64px;
}

.plans .plans-testimonials-selectors button {
  width: 10px;
  height: 10px;
  margin-right: 16px;
  background: #5c0dee;
  border-radius: 6px;
  padding: 0;
  min-width: 10px;
}

.plans .plans-testimonials-selectors button.active {
  background: #ffffff;
}

.plans .plans-checkout-cta {
  background: url("/images/bg_checkout.png");
  color: #ffffff;
  padding: 152px 96px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.plans .plans-checkout-cta-caption {
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
}

.plans .plans-checkout-cta-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 24px;
  color: #e9ddfd;
}

.plans .plans-checkout-cta-coaches {
  margin-top: 32px;
  padding-left: 25px;
  display: flex;
  align-items: center;
}

.plans .plans-checkout-cta-coaches img {
  position: relative;
  margin-left: -25px;
  margin-right: 10px;
}

.plans .plans-checkout {
  padding-top: 160px;
  padding-bottom: 40px;
}

.plans .plans-step-back {
  padding-bottom: 42px;
}

.plans .plans-step-back a {
  color: #000000;
}

.plans .plans-signup-caption {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9662f7;
  margin-bottom: 16px;
  padding-left: 10px;
}

.plans .plans-signup-caption-trial {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5a05f8;
  margin-bottom: 16px;
  padding-left: 10px;
}

.plans .plans-signup-form-element {
  margin-bottom: 20px;
  padding: 0 10px;
}

.plans .plans-signup-form-element fieldset {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.plans .plans-signup-form-element .plan-button {
  margin: 0;
}

.plans .plans-checkout-progress {
  text-align: center;
  margin-top: 35px;
}

.plans .plans-checkout-progress.mobile {
  display: none;
}

.plans .plans-checkout-progress span {
  width: 43px;
  height: 8px;
  background: #f2f4f7;
  mix-blend-mode: multiply;
  border-radius: 4px;
  margin: 0 6px;
  display: inline-block;
}

.plans .plans-checkout-progress span.plans-checkout-progress-done {
  background-color: #30077c;
}

.plans .plans-summary-caption {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.plans .plans-summary-row {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plans .plans-summary-row span {
  font-weight: 600;
  color: #000000;
}

.plans-payment-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 32px;
  color: #475467;
}

.plans-payment-caption {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9662f7;
}

.plan-success-mark {
  border-radius: 25px;
  color: #5c0dee;
  background: #e9ddfd;
  vertical-align: middle;
  padding: 6px;
  width: 1.8em !important;
  height: 1.8em !important;
  margin-top: 150px;
  margin-bottom: 38px;
}

.plan-success-caption {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.plan-success-subcaption {
  padding-top: 12px;
  padding-bottom: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475467;
}

.plan-success-apps {
  text-align: center;
}

.plan-success-apps a {
  margin: 0 15px;
}

.plans .plan-compare-mobile {
  display: none;
}

#mobile-menu h5 {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 7px;
}

@media (min-width: 960px) and (max-width: 1180px) {
  .plans .plan-card {
    margin: 0 10px;
    padding: 20px;
  }

  .plans .plan-card-caption {
    font-size: 15px;
    line-height: 25px;
  }

  .plans .plan-card-caption span {
    border-radius: 16px;
    padding: 2px 9px;
    line-height: 18px;
  }
  .plans .plan-card-price span {
    font-size: 41px;
    line-height: 52px;
  }

  .plans .plan-card-boot .with-3d {
    display: inline-block;
  }
}

@media (max-width: 960px) {
  .plans .plan-card {
    margin-bottom: 16px;
    height: auto;
  }
}

@media (max-width: 1128px) {
  .plans .plans-checkout {
    padding-left: 20px;
    padding-right: 20px;
  }

  .plans .plans-checkout-cta {
    display: none;
  }

  .plans .plans-checkout-progress {
    display: none;
  }

  .plans .plans-checkout-progress.mobile {
    display: block;
    margin-bottom: 24px;
  }

  .plans .plan-compare-mobile {
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #eaecf0;
  }

  .plans .plan-compare-mobile button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    color: #667085;
    margin: 0 5px;
  }

  .plans .plan-compare-mobile button.selected {
    background: #f9f5ff;
    border-radius: 6px;
    color: #6941c6;
  }

  .plans .plan-compare {
    display: none;
  }

  .plans .plan-compare.selected {
    display: block;
  }

  .plans .plan-compare-header {
    display: none;
  }

  .plans .plan-compare-caption {
    margin-bottom: 20px;
  }

  .plans .plan-compare .plan-compare-rows > div,
  .plans .plan-compare-features .plan-compare-rows > div {
    height: 80px;
  }

  .plans .plan-compare .plan-compare-rows > .plan-compare-row-double,
  .plans .plan-compare-features .plan-compare-rows > .plan-compare-row-double {
    height: 158px;
  }

  .plans .plan-compare .plan-compare-rows .plan-compare-row-double {
    height: 158px;
  }

  .plans .plan-compare:hover .plan-compare-row:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .plans .plans-banner {
    min-height: auto;
  }

  .plans .plans-banner-caption {
    padding: 16px;
    font-size: 36px;
    line-height: 44px;
    padding-top: 146px;
  }

  .plans .plans-banner-subcaption {
    padding: 16px;
    font-size: 18px;
    line-height: 28px;
  }

  .plans .plans-banner-buttons {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .plans .plans-banner-buttons .plan-button {
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .plans .plans-banner-testimonials {
    background: url("/images/banners/D-A1.svg") no-repeat;
    background-position: 40px 90px;
    background-size: 80%;
    height: 650px;
  }

  .plans .plans-banner.players .plans-banner-testimonials {
    background: url("/images/banners/D-A2.svg") no-repeat;
    background-position: 40px 90px;
    background-size: 80%;
  }

  .plans .plans-banner-testimonials {
    min-height: 370px;
    background: url("/images/banners/M-A1.svg") no-repeat;
    background-size: contain;
    height: auto;
    background-position: center center;
  }

  .plans .plans-banner.players .plans-banner-testimonials {
    background: url("/images/banners/M-A2.svg") no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .plans .plans-testimonials,
  .plans .plans-testimonials > div {
    height: auto;
  }

  .plans .plans-testimonials-body {
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    padding: 40px 24px;
  }

  .plans .plans-testimonials-text {
    font-size: 24px;
    line-height: 32px;
  }

  .plans .plans-testimonials-photo {
    border-top-right-radius: 0;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    min-height: 310px;
  }

  .plans .plans-testimonials-selectors {
    position: static;
  }
}

.plan-card-description {
  height: 48px;
}

.plan-card-button-unified-container {
  height: 182px;
  /* height: auto; */
}
